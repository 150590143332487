import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import apiJanus from "../../services/apiJanus";
import { BannerHome } from "../../components/Banners";
import { Navbar } from "../../components/Navbar";
import { Spinner } from "../../components/Spinner";
import { Footer } from "../../components/Footer";
import {
  Container,
  ContentStoresState,
  SectionSearch,
  StoreBoxState,
  TopContent,
  UnselectedStore,
} from "./styles";
import { Helmet } from "react-helmet-async";

interface Store {
  id: number;
  company_name: string;
  state_registration: string;
  city: string;
  address: string;
  district: string;
  description_delivery_plataform: string;
  description_delivery_time: string;
  description_store_time: string;
  whatsapp_number: string;
  complement: string;
  number: string;
  telefone: string;
  cep: string;
  image: string | null;
  company_status: boolean;
}

interface City {
  name: string;
  state_registration: string;
}

export const Estado = () => {
  const { estadoId } = useParams<{ estadoId: string }>();
  const navigate = useNavigate();

  const LojaImg = require("../../assets/Images/Banners_site/banner-lojas-new.webp");
  const ImgMobile = require("../../assets/Images/Banners-mobile/banner-lojas-new.webp");

  const [loading, setLoading] = useState(false);
  const [citiesInState, setCitiesInState] = useState<City[]>([]);
  const [allStores, setAllStores] = useState<Store[]>([]);

  useEffect(() => {
    const fetchStoresByState = async () => {
      try {
        setLoading(true);

        const { data } = await apiJanus.get("company/amabest-club/citys_and_state");
        const { citys } = data.content;

        const filteredCities = citys.filter(
          (city: City) =>
            city.state_registration.toLowerCase() === estadoId?.toLowerCase()
        );
        setCitiesInState(filteredCities);

        const storeRequests = filteredCities.map((city: City) =>
          apiJanus.get(`company/amabest-club?city=${city.name}`)
        );
        const storeResponses = await Promise.all(storeRequests);

        const storesCombined: Store[] = storeResponses
          .flatMap((response) => response.data.content)
          .filter((store: Store) => store.company_status === true);

        setAllStores(storesCombined);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro ao carregar lojas",
          text: "Não foi possível carregar as lojas deste estado. Por favor, tente novamente mais tarde.",
          timer: 4000,
        });
      } finally {
        setLoading(false);
      }
    };

    if (estadoId) {
      fetchStoresByState();
    }
  }, [estadoId]);

  const getStoresCountByCity = () => {
    const cityCounts: Record<string, number> = {};
    allStores.forEach((store) => {
      const cityName = store.city;
      cityCounts[cityName] = (cityCounts[cityName] || 0) + 1;
    });
    return cityCounts;
  };

  const renderCitiesWithStoreCounts = () => {
    const cityCounts = getStoresCountByCity();


    const citiesWithStores = citiesInState.filter((city) => {
      const count = cityCounts[city.name] || 0;
      return count > 0;
    });

    if (citiesWithStores.length === 0) {
      return (
        <UnselectedStore>
          Não há lojas cadastradas neste estado.
        </UnselectedStore>
      );
    }

    return (
      <ContentStoresState>
        {citiesWithStores.map((city) => {
          const count = cityCounts[city.name];
          return (
            <StoreBoxState key={city.name} onClick={() => navigate(`/lojas/${estadoId}/${city.name}`)}>
              <h4>{city.name.charAt(0).toUpperCase() + city.name.slice(1).toLowerCase()}</h4>
              <p>{count} lojas</p>
            </StoreBoxState>
          );
        })}
      </ContentStoresState>
    );
  };

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Lojas em {estadoId?.toUpperCase()}</title>
        <link
          rel="canonical"
          href={`https://thebestacai.com.br/lojas/${estadoId}`}
        />
      </Helmet>
      <Navbar />
      <BannerHome
        img={LojaImg}
        alt={`Banner da página de lojas em ${estadoId}`}
        text={false}
        imgMobile={ImgMobile}
      />
      <Container>
        <TopContent>
          <SectionSearch>
            <h1>Lojas em  {estadoId?.toUpperCase()}</h1>
          </SectionSearch>
        </TopContent>
        {loading ? <Spinner /> : renderCitiesWithStoreCounts()}
      </Container>
      <Footer />
    </>
  );
};
