import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import apiJanus from "../../services/apiJanus";
import allBrazilStates from "../../helpers/allBrazilStates.json";
import { BannerHome } from "../../components/Banners";
import { Navbar } from "../../components/Navbar";
import { Spinner } from "../../components/Spinner";
import { Footer } from "../../components/Footer";
import {
  ButtonLocation,
  Container,
  ContentSelect,
  ContentStoresState,
  SectionSearch,
  StoreBoxState,
  TopContent,
} from "./styles";
import { Helmet } from "react-helmet-async";

interface Store {
  id: number;
  company_name: string;
  state_registration: string;
  city: string;
  address: string;
  district: string;
  description_delivery_plataform: string;
  description_delivery_time: string;
  description_store_time: string;
  whatsapp_number: string;
  complement: string;
  number: string;
  telefone: string;
  cep: string;
  image: string;
}

type City = {
  name: string;
  state_registration: string;
};

type State = {
  nome: string;
  sigla: string;
};

export const Lojas = () => {
  const navigate = useNavigate();

  const LojaImg = require("../../assets/Images/Banners_site/banner-lojas-new.webp");
  const defaultStore = require("../../assets/Images/icone_loja_default.webp");
  const ImgMobile = require("../../assets/Images/Banners-mobile/banner-lojas-new.webp");

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState<City[]>([]);
  const [stores, setStores] = useState<Store[]>([]);
  const [filteredStates, setFilteredStates] = useState<State[]>([]);

  useEffect(() => {
    const fetchStatesAndStores = async () => {
      try {
        setLoading(true);
        const { data } = await apiJanus.get(`/company/amabest-club/citys_and_state`);
        const { state, citys } = data.content;
        setCities(citys);

        const formattedStates = allBrazilStates.filter((allState) =>
          state.includes(allState.sigla)
        );
        setFilteredStates(formattedStates);

        const storesResponse = await apiJanus.get("/company/amabest-club");
        setStores(storesResponse.data.content);
      } catch {
        Swal.fire({
          title: "Ocorreu um erro!",
          text: "Parece que as cidades não estão disponíveis no momento.",
          timer: 4000,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchStatesAndStores();
  }, []);

  const getStoresCountByState = () => {
    const stateCounts: Record<string, number> = {};
    stores.forEach((store) => {
      const st = store.state_registration;
      stateCounts[st] = (stateCounts[st] || 0) + 1;
    });
    return stateCounts;
  };

  const renderStatesWithStoreCounts = () => {
    const stateCounts = getStoresCountByState();
    const statesWithCounts = filteredStates.map((st) => ({
      ...st,
      count: stateCounts[st.sigla] || 0
    }));

    return (
      <ContentStoresState>
        {statesWithCounts.map((st) => (
          <StoreBoxState key={st.sigla} onClick={() => navigate(`/lojas/${st.sigla}`)}>
            <h4>{st.nome} - ({st.sigla})</h4>
            <p>{st.count} lojas</p>
          </StoreBoxState>
        ))}
      </ContentStoresState>
    );
  };

  const getLocation = async () => {
    try {
      const { data } = await axios.get("https://ipapi.co/json");
      const { city, region_code } = data;

      if (city && region_code) {
        const normalizedCity = city.toLowerCase();
        const normalizedState = region_code.toLowerCase();

        localStorage.setItem(
          "user_geolocation",
          JSON.stringify({ city: normalizedCity, state: normalizedState })
        );

        navigate(`/lojas/${normalizedState}/${normalizedCity}`); 
      } else {
        Swal.fire("Não foi possível identificar sua cidade e estado.");
      }
    } catch (error) {
      console.error("Erro ao obter localização:", error);
      Swal.fire("Não foi possível obter sua localização.");
    }
  };

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Nossas Lojas</title>
        <link rel="canonical" href={`https://thebestacai.com.br/lojas`} />
        <meta property="og:image" content={LojaImg} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={LojaImg} />
      </Helmet>
      <Navbar />
      <BannerHome img={LojaImg} alt="Banner Nossas Lojas" text={false} imgMobile={ImgMobile} />
      <Container>
        <TopContent>
          <SectionSearch>
            <h1>Nossas Lojas</h1>
            <ContentSelect>
              <ButtonLocation onClick={getLocation}>Localizar loja mais próxima</ButtonLocation>
            </ContentSelect>
          </SectionSearch>
        </TopContent>
        {loading ? <Spinner /> : renderStatesWithStoreCounts()}
      </Container>
      <Footer />
    </>
  );
};
