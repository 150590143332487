import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import apiJanus from "../../services/apiJanus";
import { BannerHome } from "../../components/Banners";
import { Navbar } from "../../components/Navbar";
import { Spinner } from "../../components/Spinner";
import { Footer } from "../../components/Footer";
import {
  BoxLeftContent,
  Container,
  ContentStores,
  StoreBox,
  StoreImage,
  TopContent,
  UnselectedStore,
  ContentInfo,
  DefaultImage,
} from "./styles";
import { Helmet } from "react-helmet-async";

interface Store {
  id: number;
  company_name: string;
  state_registration: string;
  city: string;
  address: string;
  district: string;
  description_delivery_plataform: string;
  description_delivery_time: string;
  description_store_time: string;
  whatsapp_number: string;
  complement: string;
  number: string;
  telefone: string;
  cep: string;
  image: string | null;
  company_status: boolean;
}

export const Cidade = () => {
  const { estado, cidade } = useParams<{ estado: string; cidade: string }>();
  const defaultStore = require("../../assets/Images/icone_loja_default.webp");
  const LojaImg = require("../../assets/Images/Banners_site/banner-lojas-new.webp");
  const ImgMobile = require("../../assets/Images/Banners-mobile/banner-lojas-new.webp");

  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState<Store[]>([]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        setLoading(true);

        const { data } = await apiJanus.get(
          `company/amabest-club?city=${cidade}`
        );

        const activeStores = data.content.filter(
          (store: Store) => store.company_status === true
        );
        setStores(activeStores);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro ao carregar lojas",
          text: `Não foi possível carregar as lojas da cidade ${cidade?.toUpperCase()}. Tente novamente mais tarde.`,
        });
      } finally {
        setLoading(false);
      }
    };

    if (cidade) {
      fetchStores();
    }
  }, [cidade]);

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Lojas em {cidade?.toUpperCase()}</title>
        <link
          rel="canonical"
          href={`https://thebestacai.com.br/lojas/${estado}/${cidade}`}
        />
      </Helmet>
      <Navbar />
      <BannerHome
        img={LojaImg}
        alt={`Banner da página de lojas em ${cidade}`}
        text={false}
        imgMobile={ImgMobile}
      />
      <Container>
        <TopContent>
          <h1>
            Lojas em {cidade?.toUpperCase()}-{estado?.toUpperCase()}
          </h1>
        </TopContent>

        {loading ? (
          <Spinner />
        ) : (
          <ContentStores>
            {stores.length > 0 ? (
              stores.map((store) => (
                <StoreBox key={store.id}>
                  {!store?.image ? (
                    <DefaultImage
                      src={defaultStore}
                      alt="Imagem ilustrativa de uma loja"
                    />
                  ) : (
                    <StoreImage src={store?.image} alt="Imagem das lojas" />
                  )}
                  <BoxLeftContent>

                    <h4>
                      {store?.address}, {store?.number} - {store?.district}{" "}
                      {store?.complement ? "- " + store?.complement : ""}
                    </h4>
                    <ContentInfo>
                      <h6>
                        ⏰ <strong>Horário:</strong>{" "}
                        {store.description_store_time || "Não informado"}
                      </h6>
                      <h6>
                        🛵 <strong>Delivery:</strong>{" "}
                        {store.description_delivery_time || "Não informado"}
                      </h6>
                      <h6>
                        📱 <strong>Plataformas:</strong>{" "}
                        {store.description_delivery_plataform || "Não informado"}
                      </h6>
                      <h6>
                        📞 <strong>Telefone:</strong>{" "}
                        {store.telefone
                          ? `(${store.telefone.slice(
                            0,
                            2
                          )}) ${store.telefone.slice(
                            2,
                            7
                          )}-${store.telefone.slice(7)}`
                          : "Não informado"}
                      </h6>
                      {store.whatsapp_number && (
                        <h6>
                          📞 <strong>Whatsapp:</strong>{" "}
                          <a
                            href={`https://api.whatsapp.com/send?phone=55${store.whatsapp_number}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            ({store.whatsapp_number.slice(
                              0,
                              2
                            )}) {store.whatsapp_number.slice(
                              2,
                              7
                            )} - {store.whatsapp_number.slice(7, 11)}
                          </a>
                        </h6>
                      )}
                    </ContentInfo>

                    {/* <Link to={`/lojas/${store.state_registration}/${store.city}/${store.id}`}>
                      Ver Mais
                    </Link> */}
                  </BoxLeftContent>
                </StoreBox>
              ))
            ) : (
              <UnselectedStore>
                Não há lojas cadastradas nesta cidade.
              </UnselectedStore>
            )}
          </ContentStores>
        )}
      </Container>
      <Footer />
    </>
  );
};
